import React, { useEffect } from "react";
import { navigate } from "gatsby";

import SEO from "../components/atoms/seo";
import Layout from "../components/organisms/layout";
import Heading from "../components/molecules/heading";

const HomePage = () => {
  useEffect(() => {
    navigate("/lp/product-page/arthromax/");
  }, []);

  return (
    <Layout hideSecureForm>
      <SEO title="Home Page" />
    </Layout>
  );
};

export default HomePage;
